import { App, Button, Divider, Dropdown, MenuProps, Modal, Tooltip, Typography } from "antd";
import { useDeepDiveContext } from "../DeepDiveProvider";
import { AUDIO_CHANNELS_TYPE, CALL_END_REASON, MORE_ITEMS, RemoteStreams, SIGNAL_TYPE, TOAST_TIME, USER_TYPE } from "../constants";
import CustomIcon from "./CustomIcon";
import { AudioChannelProps, Feature, Joinee, SessionInfo, Uids, AudioChannelsInterface, ConstraintsInterface } from "../deepdiveSlice";
import InviteModal from "./layouts/InviteModal";
import { api } from "../api";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Chat from "./layouts/Chat";
import Attendees from "./layouts/Attendees";
import VideoCallTimer from "./layouts/VideoCallTimer";
import Notes from "./layouts/Notes";
import { useRTMContext } from "../RTMProvider";

const { Text } = Typography;

const CallControlButtons = ({ remoteStreams }: { remoteStreams: RemoteStreams }) => {
  const {
    micOn,
    cameraOn,
    screenOn,
    updateDeepDiveState,
    sessionInfo,
    quitCall,
    joinee,
    isRecorder,
    showFeature,
    setShowFeature,
    waitingParticipants,
    channel,
    members,
    recordingInfo,
    audioChannels,
    selectedAudioChannel,
    setSelectedAudioChannel,
    setSelectedAudioChannelUids,
    newChatReceived,
    setNewChatReceived,
    constraints,
    t,
    calling,
  } = useDeepDiveContext();
  const { sendSignal } = useRTMContext();
  const {
    uids = {},
    joinee: { role, id },
  } = (joinee as Joinee) || {};
  const { cameraAudioUid } = (uids as Uids) || {};
  const { name: sessionName, id: sessionId, mediaSessionKey } = (sessionInfo as SessionInfo) || {};
  const { maxAllowedUsers } = constraints as ConstraintsInterface;

  const maxParticipantsAllowed = maxAllowedUsers?.[USER_TYPE.PARTICIPANT] || 0;
  const participantsCount = (remoteStreams?.participantCamerasMics?.length || 0) + (role === USER_TYPE.TESTER ? 1 : 0);

  const sessionHasParticipant = useMemo(() => {
    return Object.values(members ?? {}).some((m) => m.role === USER_TYPE.PARTICIPANT && m.is_allowed);
  }, [members]);

  const isSampleAgoraSessionForIOS = useMemo(() => {
    return mediaSessionKey === "agoraddt";
  }, [mediaSessionKey]);

  const { joiningKey } = useParams();

  const member = useMemo(() => members && members[cameraAudioUid], [members, cameraAudioUid]);
  const memberRef = useRef(member);

  useEffect(() => {
    memberRef.current = member;
  }, [member]);

  const { message, modal } = App.useApp();

  const [isRecBtnDisabled, setIsRecBtnDisabled] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState<string | null>(null);

  const leaveCallItems = [
    {
      label: (
        <>
          <CustomIcon name='logout' />
          <Text className='font-weight-500 pl-2'>{t("LEAVE")}</Text>
        </>
      ),
      key: CALL_END_REASON.LEAVE,
    },
    {
      label: <Divider className='m-0' />,
      key: "divider",
      disabled: true,
    },
    {
      label: (
        <>
          <CustomIcon name='phonecall' />
          <Text type='danger' className='font-weight-500 pl-2'>
            {t("END_CALL")}
          </Text>
        </>
      ),
      key: CALL_END_REASON.END_CALL,
    },
  ];

  const translatorsInSession = useMemo(
    () => remoteStreams?.translatorCamerasMics?.map((stream) => stream.uid),
    [remoteStreams?.translatorCamerasMics]
  );

  const audioChannelItems = useMemo(() => {
    let audioChannelItems = [
      {
        key: AUDIO_CHANNELS_TYPE.main,
        label: (
          <div
            className={`d-flex space-between gap-2 align-items-center p-8-12 overflow-hidden ${
              selectedAudioChannel === AUDIO_CHANNELS_TYPE.main ? "bg-EAEFFD" : ""
            }`}
          >
            <div className='d-flex flex-column'>
              <Text>{t("ORIGINAL_AUDIO")}</Text>
              <Text type='secondary' className='font-size-12px'>
                {t("ORIGINAL_AUDIO_DESC")}
              </Text>
            </div>
            {selectedAudioChannel === AUDIO_CHANNELS_TYPE.main && <CustomIcon name='check' />}
          </div>
        ),
      },
    ];

    Object.entries(audioChannels || {}).forEach(([key, value]) => {
      const audioChannel = value as AudioChannelProps;
      const hasTranslatorInAudioChannel = translatorsInSession?.some((translatorId) =>
        audioChannel?.uids.includes(Number(translatorId))
      );

      if (hasTranslatorInAudioChannel) {
        audioChannelItems.push({
          key: AUDIO_CHANNELS_TYPE[key as keyof typeof AUDIO_CHANNELS_TYPE],
          label: (
            <div
              className={`d-flex space-between gap-2 align-items-center p-8-12 overflow-hidden ${
                selectedAudioChannel === AUDIO_CHANNELS_TYPE[key as keyof typeof AUDIO_CHANNELS_TYPE] ? "bg-EAEFFD" : ""
              }`}
            >
              <div className='d-flex flex-column'>
                <Text>{audioChannel?.name}</Text>
                <Text type='secondary' className='font-size-12px'>
                  {audioChannel?.label}
                </Text>
              </div>
              {selectedAudioChannel === AUDIO_CHANNELS_TYPE[key as keyof typeof AUDIO_CHANNELS_TYPE] && <CustomIcon name='check' />}
            </div>
          ),
        });
      }
    });

    return audioChannelItems;
  }, [audioChannels, selectedAudioChannel, t, translatorsInSession]);

  useEffect(() => {
    if (selectedAudioChannel !== AUDIO_CHANNELS_TYPE.main) {
      const channelExists = audioChannelItems?.find((item) => item.key === selectedAudioChannel);
      if (!channelExists) {
        setSelectedAudioChannel(AUDIO_CHANNELS_TYPE.main);
        setSelectedAudioChannelUids([]);
        message.info(t("AUTO_SWITCH_TO_ORIGINAL_CHANNEL"), TOAST_TIME);
      } else {
        const channel = audioChannels?.[selectedAudioChannel as keyof AudioChannelsInterface];
        const audioChannelUids = translatorsInSession?.filter((translatorId) => channel?.uids.includes(Number(translatorId)));
        setSelectedAudioChannelUids(audioChannelUids || []);
      }
    }
  }, [
    audioChannelItems,
    audioChannels,
    message,
    selectedAudioChannel,
    setSelectedAudioChannel,
    setSelectedAudioChannelUids,
    t,
    translatorsInSession,
  ]);

  let moreItems: MenuProps["items"] = [];

  if (role === USER_TYPE.OBSERVER) {
    moreItems.push({
      key: MORE_ITEMS.AUDIO_CHANNELS,
      label: t("AUDIO_CHANNELS"),
      children: audioChannelItems,
    });
  }

  const isRecordingInProgress = useMemo(() => {
    return Number(recordingInfo?.recProgressStartTime?.WEBPAGE) > 0 ? true : false;
  }, [recordingInfo]);

  const RecordingCounterComponent = () => {
    const [recordingCounter, setRecordingCounter] = useState(0);

    const formatTime = (milliseconds: number) => {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    useEffect(() => {
      const updateCounter = () => {
        const newCounter =
          Date.now() - Number(recordingInfo?.recProgressStartTime?.WEBPAGE) + Number(recordingInfo?.recDoneDuration?.WEBPAGE);
        setRecordingCounter(newCounter);
      };

      const interval = setInterval(updateCounter, 1000);

      updateCounter();

      return () => clearInterval(interval);
    }, []);

    return <Text className='font-size-12px'>{formatTime(recordingCounter)}</Text>;
  };

  const handleStartRecording = () => {
    setLoadingMsg("Starting recording...");
    setIsRecBtnDisabled(true);
    const recordingUrl = joiningKey ? "r" + joiningKey?.slice(1) : "";
    api
      .startRecording(sessionId, channel, recordingUrl)
      .then((response) => {
        setLoadingMsg(null);
        setIsRecBtnDisabled(false);
        const rInfo = response?.data?.recordingInfo;
        updateDeepDiveState({ recordingInfo: rInfo });
        sendSignal(
          SIGNAL_TYPE.RECORDING_STATUS_CHANGED,
          cameraAudioUid,
          id,
          memberRef.current,
          undefined,
          undefined,
          "Recording started",
          false,
          rInfo
        );
        message.success("Recording started", TOAST_TIME);
      })
      .catch(() => {
        setLoadingMsg(null);
        setIsRecBtnDisabled(false);
        message.error("Failed to start recording", TOAST_TIME);
      });
  };

  const handleStopRecording = () => {
    setLoadingMsg("Stopping recording...");
    setIsRecBtnDisabled(true);
    api
      .stopRecording(sessionId)
      .then((response) => {
        setLoadingMsg(null);
        setIsRecBtnDisabled(false);
        const rInfo = response?.data?.recordingInfo;
        updateDeepDiveState({ recordingInfo: rInfo });
        sendSignal(
          SIGNAL_TYPE.RECORDING_STATUS_CHANGED,
          cameraAudioUid,
          id,
          memberRef.current,
          undefined,
          undefined,
          "Recording stopped",
          false,
          rInfo
        );
        message.success("Recording stopped", TOAST_TIME);
      })
      .catch(() => {
        setLoadingMsg(null);
        setIsRecBtnDisabled(false);
        message.error("Failed to stop recording", TOAST_TIME);
      });
  };

  return (
    <>
      {!isRecorder && (
        <div className='d-flex align-items-center bg-white space-between position-relative zi-10k' style={{ padding: "0 1rem" }}>
          <div className='d-flex align-items-center gap-1'>
            {!isRecordingInProgress && (
              <>
                {role === USER_TYPE.MODERATOR && (
                  <Tooltip
                    rootClassName='zi-tooltip'
                    title={
                      !sessionHasParticipant
                        ? t("SESSION_HAS_NO_PARTICIPANTS_FOR_RECRORDING")
                        : loadingMsg
                        ? loadingMsg
                        : t("START_RECORDING")
                    }
                  >
                    <Button
                      type='text'
                      className='m-0 rec-btn p-0'
                      size='small'
                      onClick={() => handleStartRecording()}
                      disabled={isRecBtnDisabled || !calling || !sessionHasParticipant}
                    >
                      <CustomIcon name='startrecording' />
                    </Button>
                  </Tooltip>
                )}
                {role !== USER_TYPE.MODERATOR && <CustomIcon name='startrecording' />}
                <div className='d-flex flex-column'>
                  <Text className='font-weight-500'>{sessionName}</Text>
                  <Text className='font-size-12px'>{loadingMsg ? loadingMsg : "Recording is off"}</Text>
                </div>
              </>
            )}
            {isRecordingInProgress && (
              <>
                {role === USER_TYPE.MODERATOR && (
                  <Tooltip rootClassName='zi-tooltip' title={loadingMsg ? loadingMsg : t("STOP_RECORDING")}>
                    <Button
                      type='text'
                      className='m-0 rec-btn p-0'
                      size='small'
                      onClick={() => handleStopRecording()}
                      disabled={isRecBtnDisabled || !calling}
                    >
                      <CustomIcon name='stoprecording' />
                    </Button>
                  </Tooltip>
                )}
                {/* deliberately 'startrecroding' icon is shown here, do not change it */}
                {role !== USER_TYPE.MODERATOR && <CustomIcon name='startrecording' />}
                <div className='d-flex flex-column'>
                  <Text className='font-weight-500'>{sessionName}</Text>
                  {loadingMsg && <Text className='font-size-12px'>{loadingMsg}</Text>}
                  {!loadingMsg && (
                    <div className='d-flex align-items-center gap-1'>
                      <div style={{ width: 10, height: 10, backgroundColor: "#F53D6B", borderRadius: "50%" }}></div>
                      <RecordingCounterComponent />
                    </div>
                  )}
                </div>
              </>
            )}
            <VideoCallTimer
              onWarning={(timeLeft: number) => {
                if (!isSampleAgoraSessionForIOS) {
                  let msg = "";
                  if (timeLeft >= 1) {
                    msg = t("SESSION_WILL_END_IN_MINS", { mins: Math.floor(timeLeft) });
                  } else {
                    msg = t("SESSION_WILL_END_IN_SECS", { secs: Math.floor(timeLeft * 60) });
                  }
                  modal.info({
                    title: "Warning",
                    content: msg,
                    centered: true,
                    maskClosable: false,
                  });
                }
              }}
              onEndCall={() => {
                if (!isSampleAgoraSessionForIOS) {
                  Modal.destroyAll();
                  const msg = t("SESSION_ENDED_TIME_EXCEEDED");
                  if (role !== USER_TYPE.MODERATOR) {
                    quitCall(CALL_END_REASON.LEAVE, false, msg);
                  } else {
                    // kick out
                    quitCall(CALL_END_REASON.END_CALL, false, msg);

                    setTimeout(() => {
                      api
                        .endCall(sessionId, mediaSessionKey, 1, 1)
                        .then(() => {})
                        .catch((e) => {
                          message.error(e?.response?.data?.message, TOAST_TIME);
                        });
                    }, 500);
                  }
                }
              }}
            />
          </div>

          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              {role !== USER_TYPE.OBSERVER && (
                <Button type='text' className='btn-padoff' onClick={() => updateDeepDiveState({ micOn: !micOn })} disabled={!calling}>
                  <CustomIcon name={micOn ? "micoff" : "micon"} />
                </Button>
              )}

              {role !== USER_TYPE.OBSERVER && (
                <Button
                  type='text'
                  className='btn-padoff'
                  onClick={() => updateDeepDiveState({ cameraOn: !cameraOn })}
                  disabled={!calling}
                >
                  <CustomIcon name={cameraOn ? "cameraoff" : "cameraon"} />
                </Button>
              )}

              {role !== USER_TYPE.OBSERVER && role !== USER_TYPE.TRANSLATOR && (
                <Button
                  type='text'
                  className='btn-padoff'
                  onClick={() => updateDeepDiveState({ screenOn: !screenOn })}
                  disabled={!calling}
                >
                  <CustomIcon name={screenOn ? "screenoff" : "screenon"} />
                </Button>
              )}
            </div>

            {role !== USER_TYPE.OBSERVER && <Divider type='vertical' className='h-40px' />}

            <div className='d-flex align-items-center'>
              {/* {role === USER_TYPE.MODERATOR && (
                <Button type='text' className={`btn-padoff color-697586`}>
                  <CustomIcon name='guide' />
                </Button>
              )} */}

              {role === USER_TYPE.MODERATOR && (
                <div className='position-relative m-0 d-flex'>
                  <Tooltip title={!isRecordingInProgress ? t("BOOKMARKS_ALLOWED_IN_RECORDING_ONLY") : null}>
                    <Button
                      type='text'
                      className={`btn-padoff color-697586`}
                      disabled={!isRecordingInProgress || !calling}
                      onClick={() => {
                        api
                          .saveBookmark(sessionId, {
                            timestamp: Math.floor(Date.now() / 1000),
                            sessionStartTime: sessionInfo?.start_time || 0,
                          })
                          .then((res) => {
                            message.success(res.message, TOAST_TIME);
                          })
                          .catch(() => {
                            message.error("Failed to save bookmark", TOAST_TIME);
                          });
                      }}
                    >
                      <CustomIcon name='bookmark' />
                    </Button>
                  </Tooltip>
                </div>
              )}

              {(role === USER_TYPE.MODERATOR || role === USER_TYPE.OBSERVER) && (
                <div className='position-relative m-0 d-flex'>
                  <Button
                    type='text'
                    className={`btn-padoff ${showFeature !== Feature.NOTES ? "color-697586" : "activate-icon"}`}
                    onClick={() => setShowFeature((prev) => (prev === Feature.NOTES ? undefined : Feature.NOTES))}
                    disabled={!calling}
                  >
                    <CustomIcon name='takenote' />
                  </Button>
                  {showFeature === Feature.NOTES && <Notes />}
                </div>
              )}

              <div className='position-relative m-0 d-flex'>
                <Button
                  type='text'
                  className={`btn-padoff ${showFeature !== Feature.CHAT ? "color-697586" : "activate-icon"}`}
                  onClick={() => {
                    setShowFeature((prev) => (prev === Feature.CHAT ? undefined : Feature.CHAT));
                    setNewChatReceived(false);
                  }}
                  disabled={!calling}
                >
                  <CustomIcon name='chat' />
                  {newChatReceived && showFeature !== Feature.CHAT && <CustomIcon name='red-dot' rootClassName='red-dot r-10px' />}
                </Button>
                {showFeature === Feature.CHAT && <Chat />}
              </div>

              {role !== USER_TYPE.TESTER && (
                <div className='position-relative m-0 d-flex'>
                  <Tooltip
                    title={
                      role === USER_TYPE.MODERATOR ? t("PARTICIPANT_REQUESTED_TO_JOIN", { nbr: waitingParticipants?.length }) : null
                    }
                    open={
                      role === USER_TYPE.MODERATOR &&
                      waitingParticipants?.length > 0 &&
                      showFeature !== Feature.ATTENDEES &&
                      participantsCount < maxParticipantsAllowed
                    }
                  >
                    <Button
                      type='text'
                      className={`btn-padoff ${showFeature !== Feature.ATTENDEES ? "color-697586" : "activate-icon"}`}
                      onClick={() => setShowFeature((prev) => (prev === Feature.ATTENDEES ? undefined : Feature.ATTENDEES))}
                      disabled={!calling}
                    >
                      <CustomIcon name='attendee' />
                      {role === USER_TYPE.MODERATOR &&
                        waitingParticipants?.length > 0 &&
                        participantsCount < maxParticipantsAllowed && <CustomIcon name='red-dot' rootClassName='red-dot' />}
                    </Button>
                  </Tooltip>
                  {showFeature === Feature.ATTENDEES && <Attendees remoteStreams={remoteStreams} />}
                </div>
              )}

              {role !== USER_TYPE.TESTER && (
                <div className='position-relative m-0 d-flex'>
                  <Button
                    type='text'
                    className={`btn-padoff ${showFeature !== Feature.INVITE ? "color-697586" : "activate-icon"}`}
                    onClick={() => {
                      setShowFeature((prev) => (prev === Feature.INVITE ? undefined : Feature.INVITE));
                    }}
                    disabled={!calling}
                  >
                    <CustomIcon name='invite' />
                  </Button>
                  {showFeature === Feature.INVITE && <InviteModal />}
                </div>
              )}

              {/* <Button type='text' className={`btn-padoff color-697586`}>
                <CustomIcon name='layout' />
              </Button> */}

              {moreItems.length > 0 && (
                <Dropdown
                  overlayClassName='call-control-more-dropdown zi-11k'
                  menu={{
                    items: moreItems,
                    onClick: ({ key }) => {
                      if (key !== selectedAudioChannel) {
                        let lbl = "";
                        if (key === AUDIO_CHANNELS_TYPE.main) {
                          lbl = "Original Audio";
                          setSelectedAudioChannelUids([]);
                        } else {
                          const channel = audioChannels?.[key as keyof AudioChannelsInterface];
                          lbl = `${channel?.name} (${channel?.label})`;
                          const audioChannelUids = translatorsInSession?.filter((translatorId) =>
                            channel?.uids.includes(Number(translatorId))
                          );
                          setSelectedAudioChannelUids(audioChannelUids || []);
                        }
                        setSelectedAudioChannel(key as AUDIO_CHANNELS_TYPE);
                        message.success(t("SWITCH_TO_CHANNEL", { lbl }), TOAST_TIME);
                      }
                    },
                  }}
                  onOpenChange={() => {
                    setShowFeature((prev) => (prev === Feature.MORE ? undefined : Feature.MORE));
                  }}
                  trigger={["click"]}
                >
                  <Button
                    type='text'
                    className={`btn-padoff ${showFeature !== Feature.MORE ? "color-697586" : "activate-icon"}`}
                    disabled={!calling}
                  >
                    <CustomIcon name='more' />
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>

          <div>
            {role === USER_TYPE.MODERATOR && (
              <Dropdown
                overlayClassName='call-control-leave-dropdown zi-11k'
                menu={{
                  items: leaveCallItems,
                  onClick: ({ key }) => {
                    if (key === CALL_END_REASON.END_CALL) {
                      setShowFeature(undefined);
                      modal.confirm({
                        title: t("END_CALL"),
                        content: t("END_CALL_WARNING"),
                        centered: true,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                        onOk: () => {
                          if (!isSampleAgoraSessionForIOS) {
                            api
                              .endCall(sessionId, mediaSessionKey, 1, 0)
                              .then(() => {
                                sendSignal(
                                  SIGNAL_TYPE.END_CALL,
                                  cameraAudioUid,
                                  id,
                                  memberRef.current,
                                  undefined,
                                  undefined,
                                  t("SESSION_END_BY_MODERATOR")
                                );
                                quitCall(key);
                              })
                              .catch((e) => {
                                message.error(e?.response?.data?.message, TOAST_TIME);
                              });
                          } else {
                            sendSignal(
                              SIGNAL_TYPE.END_CALL,
                              cameraAudioUid,
                              id,
                              memberRef.current,
                              undefined,
                              undefined,
                              t("SESSION_END_BY_MODERATOR")
                            );
                            quitCall(key);
                          }
                        },
                      });
                    } else {
                      quitCall(key, false, t("NORMAL_END_CALL_REASON"), true);
                    }
                  },
                }}
                trigger={["click"]}
              >
                <Button type='default' className='border-radius-8px' danger>
                  <CustomIcon name='phonecall' />
                  <Text type='danger' className='font-weight-500'>
                    {t("LEAVE")}
                  </Text>
                  <CustomIcon name='dotsvertical' />
                </Button>
              </Dropdown>
            )}

            {role !== USER_TYPE.MODERATOR && (
              <Button
                type='default'
                className='border-radius-8px'
                danger
                onClick={() => quitCall(CALL_END_REASON.LEAVE, false, t("NORMAL_END_CALL_REASON"), true)}
              >
                <CustomIcon name='phonecall' />
                <Text type='danger' className='font-weight-500'>
                  {t("LEAVE")}
                </Text>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CallControlButtons;
