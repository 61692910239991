import { App, Button, Divider, Modal, Radio, Select, Space, Tabs, Tag, Tooltip, Typography } from "antd";
import CustomIcon from "../CustomIcon";
import {
  ATTENDEES,
  capitalizeFirstLetter,
  DEEPDIVE_ROLE_ICONS,
  RemoteStreams,
  SIGNAL_TYPE,
  TOAST_TIME,
  USER_TYPE,
  WaitingParticipantInterface,
} from "../../constants";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import SimpleBar from "simplebar-react";
import { ConstraintsInterface, Feature, Joinee, Member, SessionInfo, Uids } from "../../deepdiveSlice";
import { api } from "../../api";
import UserInfo from "./UserInfo";
import { useRTMContext } from "../../RTMProvider";

const { Title, Text } = Typography;

const RolesSelection = ({ remoteStream, uid }: { remoteStream: Member; uid: number }) => {
  const { members, sessionInfo, joinee, channel, getMembers, audioChannels, updateDeepDiveState, t } = useDeepDiveContext();
  const { sendSignal } = useRTMContext();

  const { id: sessionId } = sessionInfo as SessionInfo;
  const {
    joinee: { id: joineeId },
    uids = {},
  } = joinee as Joinee;
  const { cameraAudioUid } = uids as Uids;

  const member = useMemo(() => members && members[cameraAudioUid], [members, cameraAudioUid]);
  const memberRef = useRef(member);

  const { message } = App.useApp();

  const [showAudioChannelsModal, setShowAudioChannelsModal] = useState<USER_TYPE | undefined>(undefined);
  const [selectedAudioChannel, setSelectedAudioChannel] = useState(null);

  const userName = remoteStream?.name || "";
  const currentRole = remoteStream?.role as USER_TYPE;

  if (remoteStream?.is_mobile) {
    const icon = DEEPDIVE_ROLE_ICONS[currentRole?.toLowerCase() as keyof typeof DEEPDIVE_ROLE_ICONS];

    return (
      <Tooltip title={t("MOBILE_ROLE_UPDATE_NOT_ALLOWED", { role: capitalizeFirstLetter(currentRole) })} rootClassName='zi-tooltip'>
        <div className='d-flex align-items-center gap-1'>
          <CustomIcon name='phone-02' width={15} height={15} />
          <CustomIcon name={icon} width={15} height={15} />
          {capitalizeFirstLetter(currentRole)}
        </div>
      </Tooltip>
    );
  }

  const handleRoleChange = (roleToUpdate: USER_TYPE) => {
    if (roleToUpdate === USER_TYPE.TRANSLATOR) {
      setShowAudioChannelsModal(roleToUpdate);
    } else {
      changeRole(roleToUpdate);
    }
  };

  const changeRole = (roleToUpdate: USER_TYPE, translatorAudioChannel: string | null = null) => {
    api
      .updateRole(sessionId, channel, sessionInfo?.sessionMode || 0, uid, roleToUpdate, translatorAudioChannel)
      .then((res) => {
        const { isReloadRequired, audioChannels } = res?.data?.updatedRole;
        getMembers();
        const actOn: WaitingParticipantInterface = {
          uid,
          member: { ...remoteStream, role: roleToUpdate },
        };
        sendSignal(
          SIGNAL_TYPE.ROLE_UPDATED,
          cameraAudioUid,
          joineeId,
          memberRef.current,
          actOn,
          undefined,
          undefined,
          isReloadRequired,
          undefined,
          audioChannels
        );
        updateDeepDiveState({ audioChannels });
      })
      .catch((e) => {
        message.error(e?.response?.data?.message, TOAST_TIME);
      });
  };

  const audioChannelsOptions = [
    { value: "c1", label: `${audioChannels?.c1?.name} (${audioChannels?.c1?.label})` },
    { value: "c2", label: `${audioChannels?.c2?.name} (${audioChannels?.c2?.label})` },
  ];

  return (
    <>
      <Select
        value={currentRole}
        onChange={handleRoleChange}
        options={[
          {
            value: USER_TYPE.MODERATOR,
            label: (
              <div className='d-flex align-items-center gap-1'>
                <CustomIcon name={DEEPDIVE_ROLE_ICONS.moderator} width={15} height={15} />
                {capitalizeFirstLetter(USER_TYPE.MODERATOR)}
              </div>
            ),
          },
          {
            value: USER_TYPE.TRANSLATOR,
            label: (
              <div className='d-flex align-items-center gap-1'>
                <CustomIcon name={DEEPDIVE_ROLE_ICONS.translator} width={15} height={15} />
                {capitalizeFirstLetter(USER_TYPE.TRANSLATOR)}
              </div>
            ),
          },
          {
            value: USER_TYPE.OBSERVER,
            label: (
              <div className='d-flex align-items-center gap-1'>
                <CustomIcon name={DEEPDIVE_ROLE_ICONS.observer} width={15} height={15} />
                {capitalizeFirstLetter(USER_TYPE.OBSERVER)}
              </div>
            ),
          },
        ]}
      />
      <Modal
        title={t("CHOOSE_AUDIO_CHANNEL")}
        rootClassName='zi-11k'
        wrapClassName='zi-11k'
        maskClosable={false}
        open={showAudioChannelsModal !== undefined}
        destroyOnClose={true}
        onCancel={() => {
          setShowAudioChannelsModal(undefined);
          setSelectedAudioChannel(null);
        }}
        footer={null}
        width={400}
        centered
      >
        <div className='d-flex flex-column flex-center gap-2'>
          <Text className='font-weight-600'>{t("SELECT_AUDIO_CHANNEL_FOR_TRANSLATOR", { name: userName })}</Text>
          <Radio.Group
            value={selectedAudioChannel}
            onChange={(e) => {
              setSelectedAudioChannel(e.target.value);
            }}
          >
            <Space direction='vertical'>
              {audioChannelsOptions?.map((channel) => (
                <Radio key={channel.value} value={channel.value}>
                  {channel.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <Button
            type='primary'
            disabled={!selectedAudioChannel}
            onClick={() => {
              if (showAudioChannelsModal && selectedAudioChannel) {
                changeRole(showAudioChannelsModal, selectedAudioChannel);
                setShowAudioChannelsModal(undefined);
                setSelectedAudioChannel(null);
              }
            }}
          >
            {t("UPDATE_ROLE")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const Attendees = ({ remoteStreams }: { remoteStreams: RemoteStreams }) => {
  const { waitingParticipants, members, sessionInfo, joinee, setWaitingParticipants, setShowFeature, channel, constraints, t } =
    useDeepDiveContext();
  const { sendSignal } = useRTMContext();
  const { id: sessionId } = sessionInfo as SessionInfo;
  const {
    joinee: { id: joineeId, role },
    uids = {},
  } = joinee as Joinee;
  const { cameraAudioUid } = uids as Uids;
  const { maxAllowedUsers } = constraints as ConstraintsInterface;

  const { message } = App.useApp();

  const member = useMemo(() => members && members[cameraAudioUid], [members, cameraAudioUid]);
  const memberRef = useRef(member);

  useEffect(() => {
    memberRef.current = member;
  }, [member]);

  const [activeKey, setActiveKey] = useState<keyof typeof ATTENDEES>(ATTENDEES.PARTICIPANTS);
  const [disableAllowBtn, setDisableAllowBtn] = useState(false);

  const maxParticipantsAllowed = maxAllowedUsers?.[USER_TYPE.PARTICIPANT] || 0;
  const maxModeratorsAllowed = maxAllowedUsers?.[USER_TYPE.MODERATOR] || 0;
  const maxTranslatorsAllowed = maxAllowedUsers?.[USER_TYPE.TRANSLATOR] || 0;
  const maxObserversAllowed = maxAllowedUsers?.[USER_TYPE.OBSERVER] || 0;

  const participantsCount = (remoteStreams?.participantCamerasMics?.length || 0) + (role === USER_TYPE.TESTER ? 1 : 0);
  const moderatorsCount = (remoteStreams?.moderatorCamerasMics?.length || 0) + (role === USER_TYPE.MODERATOR ? 1 : 0);
  const translatorsCount = (remoteStreams?.translatorCamerasMics?.length || 0) + (role === USER_TYPE.TRANSLATOR ? 1 : 0);
  const observersCount = (remoteStreams?.observerCamerasMics?.length || 0) + (role === USER_TYPE.OBSERVER ? 1 : 0);

  const membersCount = moderatorsCount + translatorsCount + observersCount;

  const handleAllowParticipant = (user: WaitingParticipantInterface) => {
    setDisableAllowBtn(true);
    api
      .allowParticipant(sessionId, user?.uid, user?.member?.role || "", sessionInfo?.sessionMode || 0, channel)
      .then(() => {
        sendSignal(SIGNAL_TYPE.PARTICIPANT_ALLOWED, cameraAudioUid, joineeId, memberRef.current, user);
        message.success(
          t("USER_JOINED", { name: user?.member?.name, role: capitalizeFirstLetter(user?.member?.role || "") }),
          TOAST_TIME
        );
        setWaitingParticipants((prev) => prev.filter((participant) => participant.uid !== user?.uid));
        setDisableAllowBtn(false);
      })
      .catch((e) => {
        setDisableAllowBtn(false);
        console.log("Failed to allow participant: ", e, " , user details: ", user);
        sendSignal(
          SIGNAL_TYPE.PARTICIPANT_DENIED,
          cameraAudioUid,
          joineeId,
          memberRef.current,
          user,
          undefined,
          e?.response?.data?.message
        );
        message.error(t("FAILED_TO_ALLOW", { name: user?.member?.name }), TOAST_TIME);
      });
  };

  const ParticipantsTab = () => {
    return (
      <div className='p-2 pt-1'>
        <SimpleBar className='w-100 pr-2' style={{ height: "calc(100vh - 230px)" }}>
          {role === USER_TYPE.MODERATOR && waitingParticipants.length > 0 && (
            <div>
              <Text type='secondary' className='font-size-12px font-weight-600'>
                {t("WAITING_PARTICIPANTS")} {waitingParticipants.length}
              </Text>
              <div className='d-flex flex-column gap-2 pt-2'>
                {waitingParticipants?.map((user) => (
                  <div className='d-flex space-between align-items-center' key={user?.uid}>
                    <UserInfo userName={user?.member?.name || ""} />
                    <Tooltip
                      title={participantsCount >= maxParticipantsAllowed ? t("ROOM_FULL_NO_PARTICIPANT_CAN_JOIN") : null}
                      rootClassName='zi-tooltip'
                    >
                      <Button
                        type='default'
                        size='small'
                        className='color-364152'
                        onClick={() => handleAllowParticipant(user)}
                        disabled={disableAllowBtn || participantsCount >= maxParticipantsAllowed}
                      >
                        {t("ALLOW")}
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>
              <Divider />
            </div>
          )}

          <div>
            <Text type='secondary' className='font-size-12px font-weight-600'>
              {t("PARTICIPANTS")} {participantsCount}/{maxParticipantsAllowed}
            </Text>
            <div className='d-flex flex-column gap-2 pt-2'>
              {remoteStreams?.participantCamerasMics?.map((user) => {
                const remoteStream = members && user?.uid ? members[Number(user.uid)] : null;
                return (
                  <div className='d-flex space-between align-items-center' key={user?.uid}>
                    <UserInfo userName={remoteStream?.name || ""} />
                  </div>
                );
              })}
              {role === USER_TYPE.TESTER && (
                <div className='d-flex space-between align-items-center'>
                  <UserInfo userName={member?.name || ""} isSelf={true} />
                </div>
              )}
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  };

  const MembersTab = () => {
    return (
      <div className='p-2 pt-1'>
        <SimpleBar className='w-100 pr-2' style={{ height: "calc(100vh - 230px)" }}>
          <div className='pb-2'>
            <Text type='secondary' className='font-size-12px font-weight-600'>
              {t("MODERATORS")} {moderatorsCount}/{maxModeratorsAllowed}
            </Text>
            <div className='d-flex flex-column gap-2 pt-2'>
              {remoteStreams?.moderatorCamerasMics?.map((user) => {
                const remoteStream = members && user?.uid ? members[Number(user.uid)] : undefined;
                return (
                  <div className='d-flex space-between align-items-center' key={user?.uid}>
                    <UserInfo userName={remoteStream?.name || ""} />
                    {role === USER_TYPE.MODERATOR && remoteStream && (
                      <RolesSelection remoteStream={remoteStream} uid={Number(user?.uid)} />
                    )}
                  </div>
                );
              })}
              {role === USER_TYPE.MODERATOR && (
                <div className='d-flex space-between align-items-center'>
                  <UserInfo userName={member?.name || ""} isSelf={true} />
                </div>
              )}
            </div>
          </div>
          <Divider className='m-0' />
          <div className='pb-2 pt-2'>
            <Text type='secondary' className='font-size-12px font-weight-600'>
              {t("TRANSLATORS")} {translatorsCount}/{maxTranslatorsAllowed}
            </Text>
            <div className='d-flex flex-column gap-2 pt-2'>
              {remoteStreams?.translatorCamerasMics?.map((user) => {
                const remoteStream = members && user?.uid ? members[Number(user.uid)] : undefined;
                return (
                  <div className='d-flex space-between align-items-center' key={user?.uid}>
                    <UserInfo userName={remoteStream?.name || ""} />
                    {role === USER_TYPE.MODERATOR && remoteStream && (
                      <RolesSelection remoteStream={remoteStream} uid={Number(user?.uid)} />
                    )}
                  </div>
                );
              })}
              {role === USER_TYPE.TRANSLATOR && (
                <div className='d-flex space-between align-items-center'>
                  <UserInfo userName={member?.name || ""} isSelf={true} />
                </div>
              )}
            </div>
          </div>
          <Divider className='m-0' />
          <div className='pb-2 pt-2'>
            <Text type='secondary' className='font-size-12px font-weight-600'>
              {t("OBSERVERS")} {observersCount}/{maxObserversAllowed}
            </Text>
            <div className='d-flex flex-column gap-2 pt-2'>
              {remoteStreams?.observerCamerasMics?.map((user) => {
                const remoteStream = members && user?.uid ? members[Number(user.uid)] : undefined;
                return (
                  <div className='d-flex space-between align-items-center' key={user?.uid}>
                    <UserInfo userName={remoteStream?.name || ""} />
                    {role === USER_TYPE.MODERATOR && remoteStream && (
                      <RolesSelection remoteStream={remoteStream} uid={Number(user?.uid)} />
                    )}
                  </div>
                );
              })}
              {role === USER_TYPE.OBSERVER && (
                <div className='d-flex space-between align-items-center'>
                  <UserInfo userName={member?.name || ""} isSelf={true} />
                </div>
              )}
            </div>
          </div>
        </SimpleBar>
      </div>
    );
  };

  const tabItems = [
    {
      key: ATTENDEES.PARTICIPANTS,
      label: (
        <>
          {t("PARTICIPANTS")}{" "}
          <Tag className={`border-radius-circle ${activeKey === ATTENDEES.PARTICIPANTS ? "bg-F4F1FD" : ""}`}>
            {participantsCount + waitingParticipants.length}
          </Tag>
        </>
      ),
      children: <ParticipantsTab />,
    },
    {
      key: ATTENDEES.MEMBERS,
      label: (
        <>
          {t("MEMBERS")}{" "}
          <Tag className={`border-radius-circle ${activeKey === ATTENDEES.MEMBERS ? "bg-F4F1FD" : ""}`}>{membersCount}</Tag>
        </>
      ),
      children: <MembersTab />,
    },
  ];

  return (
    <div
      style={{ width: 400, height: "calc(100% - 5rem)" }}
      className='bg-white border-radius-12px bs position-fixed top-8 right-2 zi-10k'
    >
      <div className='d-flex gap-1 p-2'>
        <Button
          className='m-0'
          size='small'
          type='text'
          onClick={() => {
            setShowFeature((prev) => (prev !== Feature.ATTENDEES ? Feature.ATTENDEES : undefined));
          }}
        >
          <CustomIcon name='x-close' />
        </Button>
        <Title className='m-0' level={4}>
          {t("ATTENDEES")}
        </Title>
      </div>

      <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key as keyof typeof ATTENDEES)} items={tabItems} centered />
    </div>
  );
};

export default Attendees;
