import { IAgoraRTCRemoteUser, RemoteUser, VideoPlayerConfig } from "agora-rtc-react";
import { AUDIO_CHANNELS_TYPE, STREAM_TYPE, USER_TYPE } from "../../constants";
import UserTrackInfo from "../UserTrackInfo";
import { useDeepDiveContext } from "../../DeepDiveProvider";
import { useMemo } from "react";
import CustomIcon from "../CustomIcon";
import { Typography } from "antd";
import { Joinee, Uids } from "../../deepdiveSlice";

const { Text } = Typography;

const RemoteUserStream = ({
  user,
  className = "",
  isSideBar = false,
}: {
  user: IAgoraRTCRemoteUser;
  className?: string;
  isSideBar?: boolean;
}) => {
  const { joinee, members, isRecorder, selectedAudioChannel, selectedAudioChannelUids, t, calling } = useDeepDiveContext();

  const { uids = {} } = joinee as Joinee;
  const { cameraAudioUid, screenUid } = uids as Uids;

  const videoPlayerConfig: VideoPlayerConfig = useMemo(() => {
    return { fit: "contain" };
  }, []);

  const remoteStream = useMemo(() => {
    return members && user.uid ? members[Number(user.uid)] : null;
  }, [members, user.uid]);

  const remoteTranslator = useMemo(() => {
    return remoteStream?.role === USER_TYPE.TRANSLATOR;
  }, [remoteStream?.role]);

  const remoteParticipantOrModerator = useMemo(() => {
    return remoteStream?.role === USER_TYPE.MODERATOR || remoteStream?.role === USER_TYPE.TESTER;
  }, [remoteStream?.role]);

  const shouldEnableAudio = useMemo(() => {
    return isRecorder && remoteTranslator
      ? false
      : (selectedAudioChannel === AUDIO_CHANNELS_TYPE.main && remoteParticipantOrModerator) ||
          (selectedAudioChannel !== AUDIO_CHANNELS_TYPE.main && selectedAudioChannelUids.includes(user.uid));
  }, [isRecorder, remoteParticipantOrModerator, remoteTranslator, selectedAudioChannel, selectedAudioChannelUids, user.uid]);

  const userName = remoteStream?.name;
  const isSelf = user?.uid === cameraAudioUid || user?.uid === screenUid;
  const isScreen = remoteStream?.stream_type === STREAM_TYPE.SCREEN;

  if (isSelf && !calling) {
    return null;
  }

  return (
    <div className={`user ${className}`} key={user.uid}>
      {!isSideBar && isScreen && (
        <div className='d-flex align-items-center gap-1 pl-2 pb-1 screenPresented'>
          <CustomIcon name='share-05' />
          {!isSelf && <Text type='secondary'>{t("PRESENTING_SCREEN", { name: userName })}</Text>}
          {isSelf && <Text type='secondary'>{t("YOUR_SCREEN_SHARED")}</Text>}
        </div>
      )}
      <RemoteUser
        user={user}
        videoPlayerConfig={videoPlayerConfig}
        playAudio={shouldEnableAudio}
        className={`remoteUser ${!isSideBar && isScreen ? "isScreen" : "h-100"}`}
      >
        {isSideBar && !isScreen && <UserTrackInfo isLocal={false} remoteUser={user} shouldEnableAudio={shouldEnableAudio} />}
      </RemoteUser>
    </div>
  );
};

export default RemoteUserStream;
