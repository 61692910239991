import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CALL_PROGRESS, CHAT_MESSAGE_TYPE, USER_TYPE } from "./constants";

export interface SessionInfo {
  userType: string;
  id: number;
  name: string;
  mediaSessionKey: string;
  chiefModerator: boolean;
  sessionStatus: number;
  logId: number;
  sessionMode: number;
  links: {
    participant: string;
    observer: string;
  };
  uniqueKey: string;
  start_time: number;
}

export interface RecordingInfoParams {
  INDIVIDUAL?: number;
  WEBPAGE?: number;
}

export interface RecordingInfo {
  recDoneDuration?: RecordingInfoParams;
  recProgressStartTime?: RecordingInfoParams;
}

export interface ChatMessage {
  timestamp: number;
  connectionID: number;
  userRole: string;
  userId: string;
  userName: string;
  messageType: CHAT_MESSAGE_TYPE.TESTER_MODERATOR | CHAT_MESSAGE_TYPE.TEAM;
  comment: string;
}

export interface ChatInterface {
  internal?: ChatMessage[];
  participant?: ChatMessage[];
}

export interface NotesInterface {
  timestamp: number;
  connectionID: number;
  userRole: string;
  userId: string;
  userName: string;
  comment: string;
  sessionStartTime: number;
}

export interface BookmarkInterface {
  timestamp: number;
  sessionStartTime: number;
  screenshot?: string;
}

export interface Uids {
  cameraAudioUid: number;
  screenUid: number;
}

export interface JoineeInfo {
  id: number;
  session_id: number;
  uid: string;
  name: string;
  userAgent: string;
  role: string;
  is_chief_moderator: boolean;
  is_mobile: boolean;
  is_allowed: boolean;
  logs: any;
  created_time: string;
  modified_time: string;
}

export interface Joinee {
  joinee: JoineeInfo;
  uids: Uids;
}

export interface Member {
  is_chief_moderator: boolean;
  is_mobile: boolean;
  name: string;
  role: string;
  stream_type: string;
  is_allowed: boolean;
}

export interface Tokens {
  cameraAudio: string;
  screen: string;
  rtmToken: string;
}

export enum Feature {
  CHAT = "CHAT",
  NOTES = "NOTES",
  ATTENDEES = "ATTENDEES",
  INVITE = "INVITE",
  MORE = "MORE",
}

export type MaxAllowedUsersInterface = {
  [key in USER_TYPE]?: number;
};

export interface ConstraintsInterface {
  maxAllowedDurationMins: number;
  maxAllowedUsers: MaxAllowedUsersInterface;
}

export interface AudioChannelProps {
  label: string;
  name: string;
  uids: number[];
}

export interface AudioChannelsInterface {
  c1: AudioChannelProps;
  c2: AudioChannelProps;
}

export interface DeepDiveState {
  callProgress: CALL_PROGRESS;
  error: any;
  sessionInfo?: SessionInfo;
  joinee?: Joinee;
  tokens?: Tokens;
  appId: string;
  channel: string;
  browserUuid: string;
  micOn: boolean;
  cameraOn: boolean;
  screenOn: boolean;
  members?: Member[];
  recordingInfo?: RecordingInfo;
  chats?: ChatInterface;
  constraints?: ConstraintsInterface;
  audioChannels?: AudioChannelsInterface;
  autoJoin: boolean;
  endReason?: string;
  showRejoinBtn?: boolean;
  notes?: NotesInterface[];
}

// Set UID in session storage if not present
const getUid = () => {
  let uuid = sessionStorage.getItem("uid") || "";
  if (!uuid) {
    uuid = crypto.randomUUID();
    sessionStorage.setItem("uid", uuid);
  }
  return uuid;
};

const initialState: DeepDiveState = {
  callProgress: CALL_PROGRESS.WAITING_SCREEN,
  error: null,
  sessionInfo: undefined,
  joinee: undefined,
  tokens: undefined,
  appId: process.env.REACT_APP_AGORA_APP_ID || "",
  channel: "",
  browserUuid: getUid(),
  micOn: false,
  cameraOn: false,
  screenOn: false,
  members: undefined,
  recordingInfo: undefined,
  chats: undefined,
  constraints: undefined,
  audioChannels: undefined,
  autoJoin: false,
  endReason: undefined,
  showRejoinBtn: false,
  notes: undefined,
};

const deepdiveSlice = createSlice({
  name: "deepdive",
  initialState,
  reducers: {
    updateState: (state, action: PayloadAction<Partial<DeepDiveState>>) => {
      return { ...state, ...action.payload };
    },
    resetState: () => initialState,
  },
});

export const { updateState, resetState } = deepdiveSlice.actions;
export default deepdiveSlice.reducer;
