import { IAgoraRTCRemoteUser } from "agora-rtc-react";
import { AudioChannelsInterface, ChatMessage, Member, RecordingInfo } from "./deepdiveSlice";

export enum CALL_PROGRESS {
  WAITING_SCREEN = "WAITING_SCREEN",
  ERROR_SCREEN = "ERROR_SCREEN",
  GET_USER_INFO_SCREEN = "GET_USER_INFO_SCREEN",
  IN_CALL_SCREEN = "IN_CALL_SCREEN",
  THANK_YOU_SCREEN = "THANK_YOU_SCREEN",
}

export enum USER_TYPE {
  TESTER = "PARTICIPANT",
  MODERATOR = "MODERATOR",
  OBSERVER = "OBSERVER",
  NOTE_TAKER = "NOTE_TAKER",
  TRANSLATOR = "TRANSLATOR",
  RECORDER = "RECORDER",
  PARTICIPANT = "PARTICIPANT",
}

export enum CALL_END_REASON {
  LEAVE = "LEAVE",
  END_CALL = "END_CALL",
}

export enum MORE_ITEMS {
  AUDIO_CHANNELS = "AUDIO_CHANNELS",
}

export enum AUDIO_CHANNELS_TYPE {
  main = "main",
  c1 = "c1",
  c2 = "c2",
}

export enum DEEPDIVE_ROLE_ICONS {
  observer = "glasses-02",
  participant = "user-01",
  tester = "user-01",
  translator = "translate",
  moderator = "headphones",
  "noteTaker" = "pencil-line",
  "co-facilitator" = "headphones",
  recorder = "headphones",
}

export enum STREAM_TYPE {
  CAMERA_MIC = "CAMERA_MIC",
  SCREEN = "SCREEN",
}

export enum SESSION_MODE {
  INDIVIDUAL = 0,
  DYAD = 1,
  TRIAD = 2,
  FOCUS_GROUP = 3,
}

export enum SIGNAL_TYPE {
  SCREEN_SHARE_STARTED = "SCREEN_SHARE_STARTED",
  PARTICIPANT_REQUSTED_TO_JOIN = "PARTICIPANT_REQUSTED_TO_JOIN",
  PARTICIPANT_ALLOWED = "PARTICIPANT_ALLOWED",
  PARTICIPANT_DENIED = "PARTICIPANT_DENIED",
  CHAT = "CHAT",
  ROLE_UPDATED = "ROLE_UPDATED",
  END_CALL = "END_CALL",
  RECORDING_STATUS_CHANGED = "RECORDING_STATUS_CHANGED",
}

export enum ATTENDEES {
  PARTICIPANTS = "PARTICIPANTS",
  MEMBERS = "MEMBERS",
}

export enum CHAT_ROOM {
  PARTICIPANT = "participant",
  INTERNAL = "internal",
}

export enum CHAT_MESSAGE_TYPE {
  TESTER_MODERATOR = "TESTER_MODERATOR",
  TEAM = "TEAM",
}

export type SignalMessage = {
  event: SIGNAL_TYPE;
  from: {
    uid: number;
    joineeId: number;
    member?: Member;
  };
  actOn: WaitingParticipantInterface | undefined;
  chat: ChatMessage | undefined;
  msg: string | undefined;
  isReloadRequired: boolean | undefined;
  recordingInfo: RecordingInfo | undefined;
  audioChannels: AudioChannelsInterface | undefined;
  timeStamp: number;
};

export type RemoteUserMap = {
  [key in USER_TYPE]?: {
    CAMERA_MIC?: IAgoraRTCRemoteUser[];
    SCREEN?: IAgoraRTCRemoteUser[];
  };
};

export type RemoteStreams = {
  moderatorScreens: IAgoraRTCRemoteUser[];
  participantScreens: IAgoraRTCRemoteUser[];
  moderatorCamerasMics: IAgoraRTCRemoteUser[];
  participantCamerasMics: IAgoraRTCRemoteUser[];
  translatorCamerasMics: IAgoraRTCRemoteUser[];
  observerCamerasMics: IAgoraRTCRemoteUser[];
};

export interface WaitingParticipantInterface {
  uid: number;
  member?: Member;
}

export const TOAST_TIME = 6; // in seconds

export const getCurrentUTCDateTime = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0");
  const day = String(now.getUTCDate()).padStart(2, "0");
  const hours = String(now.getUTCHours()).padStart(2, "0");
  const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  const seconds = String(now.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const capitalizeFirstLetter = (str: string) => {
  const source: string = str.replace("_", " ").toLowerCase();
  const arr = source.split(" ");
  const result = Array<string>();
  arr.forEach((str) => {
    result.push(str.charAt(0).toUpperCase() + str.slice(1));
  });
  return result.join(" ");
};

export const getBackground = (text: string) => {
  const colors = ["#FF7D52", "#FFC107", "#4CAF50", "#2196F3", "#9C27B0", "#FF5722", "#795548"];

  const charCode = text.toString().charAt(0).toUpperCase().charCodeAt(0);
  const colorIndex = charCode % colors.length;

  return colors[colorIndex];
};

export const formatTimestampToTime = (timestampInSeconds: number): string => {
  // Convert seconds to milliseconds (JavaScript Date uses milliseconds)
  const timestampInMillis = timestampInSeconds * 1000;

  // Create a Date object using the UTC timestamp
  const date = new Date(timestampInMillis);

  // Format the time in the user's local timezone in HH:MM AM/PM format
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // To get AM/PM format
  };

  // Use toLocaleTimeString() to get time in local timezone
  const formattedTime = date.toLocaleTimeString(undefined, options);

  return formattedTime;
};

export const createHyperlinks = (text: string) => {
  const urlRegex = /((?:https?:\/\/|www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?)/g;

  return text
    .toString()
    .replace(urlRegex, (url) => {
      const href = url.startsWith("www.") || !url.startsWith("http") ? `http://${url}` : url;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    })
    .replace(/\n/g, "<br />"); // Replace newlines after handling URLs
};
