import { axiosCall } from "./axios";
import { capitalizeFirstLetter, getCurrentUTCDateTime, USER_TYPE } from "./constants";
import { BookmarkInterface, ChatMessage, NotesInterface } from "./deepdiveSlice";

export const api = {
  verifySession: (joiningKey: string, uid: string) =>
    axiosCall({
      url: `/session/verify?key=${joiningKey}&uid=${uid}&user_agent=${navigator.userAgent}&is_mobile=false`,
      method: "get",
    }),

  updateJoinee: (
    sessionId: number,
    sessionMode: number,
    cname: string,
    id: number,
    name: string,
    role: string,
    is_chief_moderator: boolean,
    action: string
  ) => {
    const logs = { action: action, timestamp: getCurrentUTCDateTime() };
    return axiosCall({
      url: `/session/joineee/${id}`,
      method: "put",
      data: { sessionId, sessionMode, cname, name, role, is_chief_moderator, logs },
    });
  },

  sendJoinStatus: (sessionId: number, userId: string, isChiefModerator: boolean, name: string, joineeId: number, joined: boolean) => {
    if (!isChiefModerator && userId === USER_TYPE.MODERATOR) {
      userId = "Co-Facilitator";
    }

    let value = "";
    if (userId === USER_TYPE.RECORDER) {
      value = `Recording ${joined ? "started" : "stopped"}`;
    } else {
      value = `${name} (${capitalizeFirstLetter(userId)}) has ${joined ? "joined" : "left"}`;
    }

    const body = {
      timestamp: Math.floor(Date.now() / 1000),
      connectionID: joineeId,
      value,
      userType: userId,
      userId,
      joined,
    };
    axiosCall({ url: `/session/${sessionId}/joinstatus`, method: "put", data: body }).catch(() => {});
  },

  getJoinees: (sessionId: number) => axiosCall({ url: `/session/${sessionId}/joinees`, method: "get" }),

  allowParticipant: (sessionId: number, id: number, role: string, sessionMode: number, cname: string) =>
    axiosCall({ url: `/session/${sessionId}/participant/allow`, data: { id, role, sessionMode, cname }, method: "put" }),

  startRecording: (sessionId: number, channelName: string, recordingUrl: string) =>
    axiosCall({ url: "/session/recording/start", data: { sessionId, channelName, recordingUrl }, method: "post" }),

  stopRecording: (sessionId: number) => axiosCall({ url: "/session/recording/stop", data: { sessionId }, method: "post" }),

  saveChat: (sessionId: number, chat: ChatMessage) => axiosCall({ url: `/session/${sessionId}/chat`, data: chat, method: "post" }),

  updateRole: (
    sessionId: number,
    cname: string,
    sessionMode: number,
    uid: number,
    roleToUpdate: string,
    translatorAudioChannel: string | null
  ) =>
    axiosCall({
      url: `/session/${sessionId}/joinee/role`,
      data: { cname, sessionMode, uid, roleToUpdate, translatorAudioChannel },
      method: "put",
    }),

  endCall: (sessionId: number, mediaSessionKey: string, toConsiderSession: 0 | 1, kickedOut: 0 | 1) =>
    axiosCall({
      url: `/session/${sessionId}/end`,
      method: "put",
      data: { mediaSessionKey, to_consider_session: toConsiderSession, kicked_out: kickedOut },
    }),

  saveNote: (sessionId: number, notes: NotesInterface) =>
    axiosCall({ url: `/session/${sessionId}/note`, data: notes, method: "post" }),

  saveBookmark: (sessionId: number, bookmark: BookmarkInterface) =>
    axiosCall({ url: `/session/${sessionId}/bookmark`, data: bookmark, method: "post" }),
};
